import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

function About() {
  return (
    <div>
      <section className="app-background" id="about">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 ">
              {/* <h3 className="fs-6 text-secondary mb-2 text-uppercase text-center" style={{paddingTop: '10px'}}>About Us</h3> */}
              <h2 className="mb-4 display-5 text-center text-dark" style={{paddingTop: '10px'}}>Our Expertise</h2>
              <p className="fs-5 text-dark mb-5 text-center">Our clients are our top priority, and we are committed to providing them with the highest level of service.</p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-light-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row gy-4">
            {/* Card for Web Development */}
            <div className="col-6 col-md-4 col-xl-3 text-center border-primary">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo border-primary">
                <h3>Web Development</h3>
              </div>
            </div>

            {/* Card for UI/UX Design */}
            <div className="col-6 col-md-4 col-xl-3 text-center">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
                <h3>UI/UX Design</h3>
              </div>
            </div>

            {/* Card for Web Hosting */}
            <div className="col-6 col-md-4 col-xl-3 text-center">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
                <h3>Web Hosting</h3>
              </div>
            </div>

            {/* Card for User Authentication */}
            <div className="col-6 col-md-4 col-xl-3 text-center">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
                <h3>User Authentication</h3>
              </div>
            </div>

            {/* Card for Mobile Development */}
            <div className="col-6 col-md-4 col-xl-3 text-center">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
                <h3>Mobile Development</h3>
              </div>
            </div>

            {/* Card for QA & Testing */}
            <div className="col-6 col-md-4 col-xl-3 text-center">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
                <h3>QA & Testing</h3>
              </div>
            </div>

            {/* Card for API Integration */}
            <div className="col-6 col-md-4 col-xl-3 text-center">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
                <h3>API Integration</h3>
              </div>
            </div>

            {/* Card for Database Modeling */}
            <div className="col-6 col-md-4 col-xl-3 text-center">
              <div className="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
                <h3>Database Modeling</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
