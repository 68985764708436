import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Projects from './Projects';
import About from './About';
import Contact from './Contact'; // This might still be used if Contact is a standalone page
import Login from './login';
import Ad from './ad';
import Navbar from './Navbar';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<><Home />
          <About /> <Contact />       <Footer />
          </>} /> {/* Include Contact here if on the same page */}
      </Routes>
    </Router>
  );
}

export default App;