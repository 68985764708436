// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD27zSZQWodp64S0rJS0Kml0a7UJ4JOQoE",
  authDomain: "dng-solutions.firebaseapp.com",
  projectId: "dng-solutions",
  storageBucket: "dng-solutions.appspot.com",
  messagingSenderId: "564851270979",
  appId: "1:564851270979:web:ec81d5d748e8a8b2c6bbf6"
};

const app = initializeApp(firebaseConfig);

// Get the authentication service
const auth = getAuth(app); // Corrected to getAuth()

const provider = new GoogleAuthProvider(); // Create a GoogleAuthProvider instance

export { auth, provider };